import React, { useEffect, useState } from "react";
import "./finishes-types-popup.css";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";
import { fetchUserRates } from "../../services/reusableCodes";

function FinishesTypesPopup({ setFinishes }) {
  const [tables, setTables] = useState([]);
  const [rates, setRates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hoveredType, setHoveredType] = useState(null);

  useEffect(() => {
    const fetchQuotations = async () => {
      try {
        const response = await AXIOS.get(`/admin/quotations`);
        if (response.status === 200) {
          setTables(response.data);
          fetchUserRates(setRates);
        }
      } catch (error) {
        console.error("Error fetching quotations:", error);
      }
    };

    fetchQuotations();
  }, []);

  useEffect(() => {
    const fetchAllQuotations = async () => {
      try {
        const response = await AXIOS.get(`/admin/all-quotations`);
        if (response.status === 200) {
          setCategories(response.data[0].categories);
        }
      } catch (error) {
        console.error("Error fetching quotations:", error);
      }
    };

    fetchAllQuotations();
  }, []);

  const handleChange = (category, finish, type, value) => {
    setRates((prevRates) => {
      const existingRateIndex = prevRates.findIndex(
        (rate) =>
          rate.category === category &&
          rate.finish === finish &&
          rate.type === type
      );

      if (existingRateIndex !== -1) {
        // Update existing rate
        const updatedRates = [...prevRates];
        updatedRates[existingRateIndex] = {
          ...updatedRates[existingRateIndex],
          rate: value,
        };
        return updatedRates;
      } else {
        // Add new rate
        return [...prevRates, { category, finish, type, rate: value }];
      }
    });
  };

  const getRate = (category, finish, type) => {
    const rateObj = rates.find(
      (rate) =>
        rate.category.toLowerCase() === category.toLowerCase() &&
        rate.finish.toLowerCase() === finish.toLowerCase() &&
        rate.type.toLowerCase() === type.toLowerCase()
    );
    return rateObj ? rateObj.rate : "";
  };
  
  

  const handleSubmitRate = async (e) => {
    e.preventDefault();

    try {
      const transformedRates = rates.reduce((acc, rate) => {
        // Check if there is already an entry for this category in transformedRates
        const existingCategoryIndex = acc.findIndex(
          (item) => item._id === rate.category
        );

        if (existingCategoryIndex !== -1) {
          // Category exists, check if finish exists for this category
          const existingFinishIndex = acc[
            existingCategoryIndex
          ].finishes.findIndex((finish) => finish._id === rate.finish);

          if (existingFinishIndex !== -1) {
            // Finish exists, add type to existing types array
            acc[existingCategoryIndex].finishes[existingFinishIndex].types.push(
              {
                name: rate.type,
                rate: rate.rate,
              }
            );
          } else {
            // Finish does not exist, add new finish with type
            acc[existingCategoryIndex].finishes.push({
              name: rate.finish,
              types: [
                {
                  name: rate.type,
                  rate: rate.rate,
                },
              ],
            });
          }
        } else {
          // Category does not exist, create new category with finish and type
          acc.push({
            name: rate.category,
            finishes: [
              {
                name: rate.finish,
                types: [
                  {
                    name: rate.type,
                    rate: rate.rate,
                  },
                ],
              },
            ],
          });
        }

        return acc;
      }, []);

      const response = await AXIOS.post(
        "/quotation/add-rate",
        transformedRates
      );
      if (response.status === 200) {
        fetchUserRates(setRates);
      }
    } catch (error) {
      console.error(error); // Using console.error for better error visibility
    }
  };

  

  return (
    <section className="finishes-types-popup">
      <div className="finishes-types-popup-container">
        <div>
          <IoMdClose
            className="finishes-types-popup-icon"
            onClick={() => setFinishes(false)}
          />
        </div>
        <h2 className="finishes-types-popup-title">
          Add your rates here <span>(before GST)</span>
        </h2>

        <div className="finishes-popup-container">
          {categories.map((table, tableIndex) => (
            <div key={tableIndex} className="finishes-popup-category-section">
              <h2 className="finishes-popup-category-name">{table.name}</h2>
              <div className="finishes-popup-finishes-section">
                {table.finishes.map((finish, finishIndex) => (
                  <table
                    key={finishIndex}
                    className="finishes-popup-finishes-table"
                  >
                    <thead>
                      <tr className="finishes-popup-finishes-row">
                        <th className="finishes-popup-finishe-name">
                          {finish.name}
                        </th>
                        <th className="finishes-popup-rate">Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {finish.types.map((type, typeIndex) => (
                        <tr
                          className="finishes-popup-types-row"
                          key={typeIndex}
                        >
                          <td className="finishes-popup-type-name">
                            <p
                            className="finish-type-title finish-popup-type-title"
                              onMouseEnter={() =>
                                setHoveredType(
                                  `${tableIndex}-${finishIndex}-${typeIndex}`
                                )
                              }
                              onMouseLeave={() => setHoveredType(null)}
                            >
                              {type.name}
                            </p>
                            {hoveredType ===
                              `${tableIndex}-${finishIndex}-${typeIndex}` &&
                              type.description && (
                                <p className="finish-type-title-description">
                                  {type.description}
                                </p>
                              )}
                          </td>
                          <td className="finishes-popup-rate-input">
                            <span>₹</span>
                            <input
                              type="text"
                              value={getRate(
                                table._id,
                                finish._id,
                                type._id
                              )}
                              onChange={(e) =>
                                handleChange(
                                  table._id,
                                  finish._id,
                                  type._id,
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="rate-save-button" onClick={handleSubmitRate}>
        Save
      </button>
    </section>
  );
}

export default FinishesTypesPopup;
