import React, { useEffect, useRef, useState } from "react";
import "./landingPage.css";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import numberStatisticsImg from "../../assets/number_statistics.jpg";
import siteMeasurementPic from "../../assets/SiteMeasurement.png";
import tracktor from "../../assets/tracktor.jpg";
import walkthrough from "../../assets/walkthrough.jpg";
import cleaning from "../../assets/cleaning.jpg";
import floor from "../../assets/floor.jpg";
import professionalQuotation from "../../assets/ProfessionalQuotation.png";
import mobileNumberStatisticsImg from "../../assets/mobile_number_statistics.jpg";
import workImg from "../../assets/work_flow_img.png";
import AboutUsImg from "../../assets/About_us.jpg";
import quotationImg from "../../assets/quotation-img.jpg";
import { IoMdClose } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_IS_CLOSE,
  setLogin,
  setSignup,
} from "../../services/redux/actions/refreshToken.action";
import EditProjectPopup from "../../components/edit-project-popup/edit-project-popup";
import Loading from "../../components/loading/loading";
import LandingPageFinishesTypes from "../../components/landing-page-finishes-types/landingPageFinishesTypes";
import ServicesPopup from "../../components/services-popup/servicesPopup";
import { formatIndianNumber } from "../../services/reusableCodes";
import { refreshToken } from "../../services/redux/thunk/authThunk";

function LandingPage() {
  const [projects, setProjects] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const adminRole = useSelector((state) => state.auth.adminRole);
  const role = useSelector((state) => state.auth.role);
  const count = useSelector((state) => state.auth.count);
  const referredByNames = useSelector((state) => state.auth.names);
  const totalReferralDays = useSelector((state) => state.auth.days);
  const [closeCount, setCloseCount] = useState(true);
  const [editProject, setEditProject] = useState("");
  const [deleteProject, setDeleteProject] = useState({
    id: "",
    name: "",
  });
  const isClose = useSelector((state) => state.memberShip.isClose);
  const dispatch = useDispatch();
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [cities, setCities] = useState("");
  const [adminCities, setAdminCities] = useState("");
  const [getRates, setGetRates] = useState({
    location: "",
    finish: "",
    type: "",
    description: "",
    rate: "",
  });
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [isServicePopup, setIsServicePopup] = useState("");
  const servicesListRef = useRef(null);

  const handleCityChange = (event) => {
    setGetRates((prev) => ({
      ...prev,
      location: event.target.value,
      finish: "",
      type: "",
      description: "",
      rate: "",
    }));
  };

  const fetchCities = async () => {
    try {
      const response = await AXIOS.get("/all-cities-list");
      if (response.status === 200) {
        setCities(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdminCities = async () => {
    try {
      const response = await AXIOS.get("/admin/admin-cities");
      if (response.status === 200) {
        setAdminCities(response.data.cities);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const projectsData = async () => {
    try {
      const response = await AXIOS.get("/project/get-projects", {
        withCredentials: true,
      });
      if (response.status === 200) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      projectsData();
      fetchCities();
      fetchAdminCities();
    }
  }, [isAuth]);

  const filteredProjects =
    projects &&
    projects.filter((project) =>
      project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const displayedProjects = showAllProjects
    ? filteredProjects
    : filteredProjects.slice(0, 7);

  const handleSignupToggle = () => {
    dispatch(setSignup(true));
    dispatch(setLogin(false));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLoginToggle = () => {
    dispatch(setLogin(true));
    dispatch(setSignup(false));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMembershipIcon = () => {
    dispatch({ type: SET_IS_CLOSE, payload: false });
  };

  const handleDeleteProject = async (e, projectId) => {
    e.preventDefault();
    try {
      const response = await AXIOS.delete(
        `/project/delete-project/${projectId}`
      );
      if (response.status === 200) {
        projectsData();
        setDeleteProject((prev) => ({ ...prev, id: "", name: "" }));
      }
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const handleClearReferredBy = async () => {
    try {
      const response = await AXIOS.patch(`user/clear/referredBy`);
      if (response.status === 200) {
        dispatch(refreshToken());
      }
    } catch (err) {
      console.log(err);
      
    } 
  };

  // useEffect(() => {
  //   if (role === "user" && adminRole === "user") {
  //     setTimeout(() => {
  //       setIsServicePopup("quotation");
  //     }, 6000);
  //   }

  //   if (servicesListRef.current) {
  //     // Delay the scroll by 2 seconds
  //     setTimeout(() => {
  //       servicesListRef.current.scrollTo({
  //         left: servicesListRef.current.scrollWidth / 2,
  //         behavior: "smooth",
  //       });
  //     }, 1000);
  //   }
  // }, []);

  return (
    <article className="landing-page-container">
      {/* cover page section */}
      <div>
        <img
          src={landingPageImg}
          alt=""
          className={`landing-page-cover-img ${
            isAuth ? "signup-landing-page-cover-img" : ""
          }`}
        />
      </div>

     { referredByNames.length >0 && <section className="otp-popup">
        <div className="service-booking-container">
          <IoMdClose className="service-close-icon membership-close-icon" onClick={handleClearReferredBy}/>
          <div className="membership-expiry-line" >
           {`Designer ${referredByNames.join(", ")} has used your referral code!`}
            <div className="referral-instructions membership-referral-instructions" style={{padding:'0'}}>
              <p className="referral-details">{`${totalReferralDays} days have been added to your subscription!`}</p>
            </div>
          </div>
        </div>
      </section>}

      {/* member ship count  */}
      {adminRole === "Designer" && isClose && count <= 7 && (
        <section className="otp-popup">
          <div className="service-booking-container">
            <IoMdClose
              className="service-close-icon membership-close-icon"
              onClick={handleMembershipIcon}
            />
            <div className="membership-expiry-line">
              Your membership is about to expire - refer and get FREE
              membership. Click to{" "}
              <span
                onClick={() => {
                  navigate("/payment");
                  handleMembershipIcon();
                }}
              >
                Redeem.
              </span>
              <div className="referral-instructions membership-referral-instructions">
                <p className="referral-details">To refer,</p>
                <p className="referral-details">
                  Ask the user to put your Profile ID as a referral code in the
                  purchase page.
                </p>
                <p className="referral-details">
                  1 Signup = Get 6 months FREE Classic Membership for yourself.
                  Your Referral gets 3 Months Free Classic membership.
                </p>
                <p className="referral-details">
                  2 Signups = Extend FREE classic membership to one year.
                </p>
                <p className="referral-details">
                  3 Signups = Add one more FREE year to your classic membership,
                  and so on...
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* project delete popup */}
      {deleteProject.id && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to delete ${deleteProject.name} project?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={(e) => handleDeleteProject(e, deleteProject.id)}
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setDeleteProject((prev) => ({ ...prev, id: "", name: "" }))
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className={`landing-page-cover-content ${
          isAuth && adminRole !== "Designer"
            ? "auth-landing-page-cover-content "
            : ""
        } ${!isAuth ? "unauth-landing-page-cover-content" : ""}`}
      >
        {(!isAuth || adminRole === "Designer") && (
          <section className="landing-page-cover-heading">
            <h1>AI Powered Quotation Making Platform</h1>
            <p>Transforming your World into Stunning Realities</p>

            {closeCount && adminRole === "Designer" && count <= 7 && (
              <div
                className={`landing-page-cover-quotation-details ${
                  isAuth ? "signup-landing-page-cover-quotation-details" : ""
                }`}
              >
                <div className="landing-page-cover-quotation-days-section">
                  <div
                    className="landing-page-cover-quotation-details-parent"
                    id="groupContainer"
                  >
                    <div className="landing-page-cover-quotation-details-child"></div>
                    <div className="landing-page-cover-quotation-details-item"></div>
                    <div className="landing-page-cover-quotation-details-inner"></div>
                    <div className="landing-page-cover-quotation-details-div"></div>
                    <div className="landing-page-cover-quotation-days">
                      {count ? count : 0}
                    </div>
                  </div>
                  <div className="landing-page-cover-quotation-days-remaining">
                    Days remaining
                  </div>
                </div>
                <IoMdClose
                  className="landing-page-cover-quotation-cancel-icon"
                  onClick={() => setCloseCount(false)}
                />
              </div>
            )}
          </section>
        )}

        {isAuth && (
          <section className="auth-landing-page-cover-heading">
            {adminRole !== "Designer" && (
              <h1>AI Powered Quotation Making Platform</h1>
            )}
            {isAuth && role === "user" && adminRole === "user" && (
              <div className="mobile-user-access">
                <button onClick={() => navigate("/be-a-partner")}>
                  Be a Partner
                </button>
              </div>
            )}

            <div className="landing-page-rate-services">
              {adminRole !== "Designer" && (
                <div className="landing-page-rate-finder">
                  <div className="landing-page-rate-finder-content">
                    <div className="landing-page-rate-header">
                      <h2 className="landing-page-rate-title">Find the Rate</h2>

                      <div className="landing-page-city-selection">
                        <p className="landing-page-city-label">
                          Select your City
                        </p>

                        <select
                          className="landing-page-city-name"
                          value={
                            getRates.location === "Bengaluru"
                              ? "Bengaluru/Bangalore"
                              : getRates.location
                          }
                          onChange={handleCityChange}
                        >
                          <option value="" disabled selected>
                            Select City
                          </option>
                          {cities.length > 0 &&
                            cities.map((city, index) => (
                              <option key={index} value={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="landing-page-rate-container">
                      <div className="landing-page-rate-content">
                        <div className="landing-page-finishes-selection">
                          <p className="landing-page-finishes-label">
                            Select your Finishes
                          </p>

                          <div className="landing-page-finishes-inputs">
                            <input
                              type="text"
                              placeholder="Finishes"
                              className="landing-page-finishes-input"
                              readOnly
                              onClick={() =>
                                getRates.location &&
                                setActiveDropdown(!activeDropdown)
                              }
                              value={getRates.finish}
                            />
                            <input
                              type="text"
                              placeholder="Types"
                              className="landing-page-finishes-input"
                              readOnly
                              onClick={() =>
                                getRates.location &&
                                setActiveDropdown(!activeDropdown)
                              }
                              value={getRates.type}
                            />
                          </div>
                        </div>

                        <div className="landing-page-rates-display">
                          <p className="landing-page-rates-label">Rates</p>
                          <p className="landing-page-rates-value">
                            {getRates.rate
                              ? "₹ " + formatIndianNumber(getRates.rate)
                              : "-"}
                          </p>
                        </div>
                      </div>
                      {activeDropdown && (
                        <LandingPageFinishesTypes
                          setActiveDropdown={setActiveDropdown}
                          setGetRates={setGetRates}
                          getRates={getRates}
                        />
                      )}
                    </div>

                    <p className="landing-page-rate-description">
                      {getRates.location
                        ? getRates.description
                          ? getRates.description
                          : ""
                        : "Please Select Location"}
                    </p>
                  </div>
                </div>
              )}

              {/* services  */}

              <div className="landing-page-services-section">
                <h2 className="landing-page-services-title">Our Services</h2>
                <div
                  className="landing-page-services-list"
                  ref={servicesListRef}
                >
                  {/* site measurements  */}
                  <div className="landing-page-service-item">
                    <div>
                      <h6 className="landing-page-service-title">
                        Site Measurements
                      </h6>
                      <p className="landing-page-service-subtitle">
                        (CAD and Sketchup 3D)
                      </p>
                    </div>
                    <div>
                      <img
                        src={siteMeasurementPic}
                        alt="siteMeasurementPic"
                        className="landing-page-service-img"
                      />
                      <p className="landing-page-service-description">
                        Get CAD drawings and Sketchup 3D of your site at a
                        nominal cost.
                      </p>
                    </div>
                    <button
                      className="landing-page-service-button"
                      onClick={() => setIsServicePopup("site")}
                    >
                      Book
                    </button>
                  </div>

                  {/* render and walkthrough  */}
                  <div className="landing-page-service-item">
                    <div>
                      <h6 className="landing-page-service-title">
                        3D renders and walkthrough
                      </h6>
                      <p className="landing-page-service-subtitle">
                        (3d render and walkthrough )
                      </p>
                    </div>
                    <div>
                      <img
                        src={walkthrough}
                        alt="professionalQuotation"
                        className="landing-page-service-img"
                      />
                      <p className="landing-page-service-description">
                        Get stunning 3D renders and walkthrough
                      </p>
                    </div>
                    <button
                      className="landing-page-service-button"
                      onClick={() => setIsServicePopup("render")}
                    >
                      Book
                    </button>
                  </div>

                  {/* Debris Removal  */}
                  <div className="landing-page-service-item">
                    <div>
                      <h6 className="landing-page-service-title">
                        Debris Removal
                      </h6>
                      <p className="landing-page-service-subtitle">
                        (Dispose Debris from Site)
                      </p>
                    </div>
                    <div>
                      <img
                        src={tracktor}
                        alt="professionalQuotation"
                        className="landing-page-service-img"
                      />
                      <p className="landing-page-service-description">
                        Get your site clean and clear of all debris. Book our
                        Debris removal services.
                      </p>
                    </div>
                    <button
                      className="landing-page-service-button"
                      onClick={() => setIsServicePopup("debris")}
                    >
                      Book
                    </button>
                  </div>

                  {/* Deep Cleaning  */}
                  <div className="landing-page-service-item">
                    <div>
                      <h6 className="landing-page-service-title">
                        Deep Cleaning
                      </h6>
                      <p className="landing-page-service-subtitle">
                        (Deep clean your site)
                      </p>
                    </div>
                    <div>
                      <img
                        src={cleaning}
                        alt="professionalQuotation"
                        className="landing-page-service-img"
                      />
                      <p className="landing-page-service-description">
                        Get deep cleaning services after the interior work.
                        Entire house cleaning along with plastic removal &
                        washroom cleaning.
                      </p>
                    </div>
                    <button
                      className="landing-page-service-button"
                      onClick={() => setIsServicePopup("deep")}
                    >
                      Book
                    </button>
                  </div>

                  {/* floor protection  */}
                  <div className="landing-page-service-item">
                    <div>
                      <h6 className="landing-page-service-title">
                        Floor Protection
                      </h6>
                      <p className="landing-page-service-subtitle">
                        (Tarpaulin Sheet)
                      </p>
                    </div>
                    <div>
                      <img
                        src={floor}
                        alt="professionalQuotation"
                        className="landing-page-service-img"
                      />
                      <p className="landing-page-service-description">
                        Cover your floor by Tarpaulin sheets to protect from any
                        damages or scratch marks
                      </p>
                    </div>
                    <button
                      className="landing-page-service-button"
                      onClick={() => setIsServicePopup("floor")}
                    >
                      Book
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {isAuth && isServicePopup && (
          <ServicesPopup
            setIsServicePopup={setIsServicePopup}
            isServicePopup={isServicePopup}
            cities={adminCities}
          />
        )}

        {/* login sign up for mobile  */}
        {!isAuth && (
          <div className="mobile-user-access">
            <button onClick={handleLoginToggle}>Login</button>
            <button onClick={handleSignupToggle}>Signup</button>
          </div>
        )}

        <section
          className={`landing-page-cover-quotation ${
            isAuth ? "signup-landing-page-cover-quotation" : ""
          }`}
        >
          {!isAuth && (
            <h2 onClick={handleSignupToggle}>Start Making Quotation</h2>
          )}
        </section>
      </section>

      <div
        className={`landing-page-content ${
          isAuth ? "auth-landing-page-content" : ""
        }`}
      >
        {/* number statistics */}
        {!isAuth && (
          <section className="number-statistics-section">
            <img
              src={numberStatisticsImg}
              alt="mySvgImage"
              className="number-statistics-section-img"
            />
            <img
              src={mobileNumberStatisticsImg}
              alt=""
              className="mobile-number-statistics-section-img"
            />
            <div className="number-statistics">
              <div className="number-statistics-item quotations-statistics">
                <div className="number-statistics-count">10,000+</div>
                <p className="number-statistics-description">
                  Quotations Created
                </p>
              </div>

              <div className="number-statistics-divider">
                <div></div>
              </div>

              <div className="number-statistics-item interior-statistics-count">
                <div className="number-statistics-count">650+</div>
                <p className="number-statistics-description laptop-statistics-description">
                  Interior Designers using Interioverse
                </p>
                <p className="number-statistics-description mobile-statistics-description">
                  Happy Clients
                </p>
              </div>

              <div className="number-statistics-divider">
                <div></div>
              </div>

              <div className="number-statistics-item projects-statistics-count">
                <div className="number-statistics-count ">485+</div>
                <p className="number-statistics-description laptop-statistics-description">
                  Interior Project Executed via Interioverse
                </p>
                <p className="number-statistics-description mobile-statistics-description">
                  Man-Years of Experience
                </p>
              </div>
            </div>
          </section>
        )}

        {/* edit project pop up  */}
        {adminRole === "Designer" && editProject && (
          <EditProjectPopup
            editProject={editProject}
            setEditProject={setEditProject}
            projectsData={projectsData}
          />
        )}

        {/* quotation list */}
        {isAuth && adminRole === "Designer" && (
          <section className="quotation-section">
            <div className="quotation-actions-section">
              <div className="quotation-search">
                <input
                  type="text"
                  placeholder="Search Quotation"
                  className="quotation-search-input"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <IoSearch className="quotation-search-icon" />
              </div>
            </div>

            <section className="quotation-content">
              <article
                className="quotation-item new-quotation"
                onClick={() => navigate("/new-project")}
              >
                <div className="add-new" aria-label="Add New Quotation">
                  <GoPlus className="add-new-icon" />
                </div>
                <p>New Quotation</p>
              </article>

              {projects &&
                displayedProjects.map((project, index) => (
                  <article
                    className="quotation-item existing-quotation"
                    key={index}
                  >
                    <div className="edit-delete-qoutation">
                      <MdDelete
                        className="delete-qoutation-icon"
                        onClick={() =>
                          setDeleteProject((prev) => ({
                            ...prev,
                            id: project.project_id,
                            name: project.project_name,
                          }))
                        }
                      />
                      <MdEdit
                        className="edit-qoutation-icon"
                        onClick={() => setEditProject(project.project_id)}
                      />
                    </div>
                    <figure
                      onClick={() =>
                        navigate(
                          `/my-project/${project.project_id}/${project.lastVersionName}`
                        )
                      }
                    >
                      <img
                        src={quotationImg}
                        alt="Quotation for Interior Design"
                        className="quotation-image"
                      />
                      <figcaption className="quotation-info">
                        <p className="quotation-info-line">
                          Quotation for Interior Design
                        </p>
                        <p className="quotation-name">{project.project_name}</p>
                      </figcaption>
                    </figure>
                  </article>
                ))}
            </section>

            {projects.length > 7 && (
              <div className="quotation-bottom-button">
                <button
                  className="quotation-view-all-button"
                  onClick={() => setShowAllProjects(!showAllProjects)}
                >
                  {showAllProjects ? "Show Less" : "View All"}
                </button>
              </div>
            )}
          </section>
        )}

        {/* How it works */}
        <section className="how-it-works-container">
          <section className="how-it-works">
            <div className="quotation-making-video-container">
              <h2>How to Make an Interior Quotation Template</h2>
              <iframe
                className="quotation-making-video"
                src="https://www.youtube.com/embed/cC9ZHhIR6p4?rel=0&modestbranding=1&showinfo=0&iv_load_policy=3"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <div className="signup-video-container">
              <h2>How to Signup as a Partner</h2>
              <iframe
                className="signup-video"
                src="https://www.youtube.com/embed/GLg_1kicM24?rel=0&modestbranding=1&showinfo=0&iv_load_policy=3"
                title="Signup-Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              <div className="signup-video-text-container">
                {!isAuth && (
                  <button
                    onClick={handleSignupToggle}
                    className="signup-video-button"
                  >
                    Sign Up
                  </button>
                )}
                {!isAuth && (
                  <p className="signup-video-text">
                    Sign up now to access our AI-powered interior quotation
                    tool. No more calling vendors and contractors to know the
                    market rates. Discover accurate rates in your city. Signup
                    Now!
                  </p>
                )}
                {isAuth && adminRole === "user" && (
                  <button
                    onClick={() => navigate("/be-a-partner")}
                    className="signup-video-button"
                  >
                    Be a Partner
                  </button>
                )}
                {isAuth && adminRole === "user" && (
                  <p className="signup-video-text">
                    As a design partner, unlock exclusive tools like an
                    AI-powered quotation generator. Craft beautiful quotes
                    effortlessly and be part of a vibrant creative community.
                    Join us today!
                  </p>
                )}
                {isAuth && adminRole === "Designer" && (
                  <button
                    onClick={() => navigate("/quotation-template")}
                    className="signup-video-button"
                  >
                    Create Template
                  </button>
                )}
                {isAuth && adminRole === "Designer" && (
                  <p className="signup-video-text">
                    Ready to streamline your interior projects? Create your own
                    brand template and make quotation using our AI tools. Save
                    your rates, make efficient workflows and stunning quotation
                    in mins !
                  </p>
                )}
                {isAuth && adminRole !== "Designer" && adminRole !== "user" && (
                  <p className="signup-video-text no-button-video-text">
                    Refer a designer to use our Ai powered quotation making
                    tool. Make quotation with ease and streamline the workflows.
                  </p>
                )}
              </div>
            </div>
          </section>

          <section className="how-it-works">
            <article className="how-it-works-content">
              <h2>How This Works</h2>
              <p className="how-it-works-tag-line">
                Get Stunning quotation in simple and easy steps
              </p>
              <section>
                <div className="how-it-works-steps">
                  <div className="how-it-works-step">
                    <h3>Step 1</h3>
                    <div className="how-it-works-step-details">
                      <h4>Create</h4>
                      <p>Create the project by entering the project details</p>
                    </div>
                  </div>
                  <div className="how-it-works-step">
                    <h3>Step 2</h3>
                    <div className="how-it-works-step-details">
                      <h4>Modify</h4>
                      <p>
                        Modify the quotation as per your client’s requirement
                      </p>
                    </div>
                  </div>
                  <div className="how-it-works-step">
                    <h3>Step 3</h3>
                    <div className="how-it-works-step-details">
                      <h4>Download</h4>
                      <p>
                        Review your quote and download it. Send it via Whatsapp
                        or Email to your client.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </article>
            <div className="quotation-making-video-container">
              <h2>Quick Quotation Making</h2>
              <iframe
                className="quotation-making-video"
                src="https://www.youtube.com/embed/AjSGJWY9pOU?rel=0&modestbranding=1&showinfo=0&iv_load_policy=3"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </section>
        </section>

        {/* About us */}
        <section className="about-us">
          <img
            src={AboutUsImg}
            alt="Interioverse design samples"
            className="about-us-image"
          />

          <article className="about-us">
            <div className="about-us-content">
              <h2 className="about-us-title">Interioverse</h2>
              <h3 className="about-us-subtitle">
                Transforming your world into stunning realities!
              </h3>
              <p className="about-us-text">
                Welcome to Interioverse, where dreams become designs!
              </p>
              <p className="about-us-text">
                Dive into a world of endless possibilities as we transform
                spaces and lives. Discover the power of innovative design
                solutions that elevate every corner of your home or office.
              </p>
              <p className="about-us-text">
                Let's embark on this exciting journey together and turn your
                visions into vibrant realities. Join us in creating the space of
                your dreams – it's time to ignite your imagination and design
                with Interioverse!
              </p>
            </div>
          </article>
        </section>
      </div>
    </article>
  );
}

export default LandingPage;
